<template>
  <div class="popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】驾驶员信息' : (todo == 'edit' ? '【修改】驾驶员信息' : '【查看】驾驶员信息')"
      width="60%"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="驾驶员姓名" prop="name">
              <el-input v-model="dataForm.name" placeholder="驾驶员姓名" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属车队" prop="motorcadeId">
              <el-select class="item-choose" v-model="dataForm.motorcadeId" :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in motorcadeList"
                  :key="item.id"
                  :label="item.simpleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contactPhone">
              <el-input v-model="dataForm.contactPhone" placeholder="可输入手机号码或固定电话" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="紧急联系电话" prop="urgentPhone">
              <el-input v-model="dataForm.urgentPhone" placeholder="可输入手机号码或固定电话" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="入职时间" prop="contactPhone">
              <el-date-picker v-model="dataForm.entryDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                              :disabled="isView" style="width: 100%">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="离职日期" prop="quitDate">
              <el-date-picker v-model="dataForm.quitDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                              :disabled="isView" style="width: 100%">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="现居住城市" prop="dwellCity">
              <el-input v-model="dataForm.nowCity" placeholder="现居住城市" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现居地址" prop="address">
              <el-input v-model="dataForm.address" placeholder="现居地址" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="开户行" prop="bankType">
              <el-select class="item-choose" v-model="dataForm.bankType" :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择开户行">
                <el-option
                  v-for="item in dictTypeMap.bank_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行卡号" prop="bankCard">
              <el-input v-model="dataForm.bankCard" placeholder="银行卡号" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="是否自带车辆" prop="isHaveVehicle">
              <el-select class="item-choose" v-model="dataForm.isHaveVehicle" :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.yes_no"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="籍贯" prop="nativeCity">
              <el-input v-model="dataForm.nativeCity" placeholder="籍贯" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="准驾类型" prop="proofType">
              <el-select class="item-choose" v-model="dataForm.proofType" :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.drive_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号码" prop="cardNo">
              <el-input v-model="dataForm.cardNo" placeholder="身份证号码" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="身份证正面">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :disabled="isView"
                :data="{businessType: cardType}"
                :headers="{'access_token': accessToken}"
                :on-success="saveFileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                :show-file-list="false">
                <img v-if="getPhotoType(cardType)" :src="getPhotoType(cardType)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证反面">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :disabled="isView"
                :data="{businessType: otherCardType}"
                :headers="{'access_token': accessToken}"
                :on-success="saveFileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                :show-file-list="false">
                <img v-if="getPhotoType(otherCardType)" :src="getPhotoType(otherCardType)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="驾驶证">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :disabled="isView"
                :data="{businessType: driverProofType}"
                :headers="{'access_token': accessToken}"
                :on-success="saveFileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                :show-file-list="false">
                <img v-if="getPhotoType(driverProofType)" :src="getPhotoType(driverProofType)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="从业资格证">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :disabled="isView"
                :data="{businessType: qualificationType}"
                :headers="{'access_token': accessToken}"
                :on-success="saveFileList"
                accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
                :show-file-list="false">
                <img v-if="getPhotoType(qualificationType)" :src="getPhotoType(qualificationType)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="是否车队长" prop="captain">
              <el-select class="item-choose" v-model="dataForm.captain" :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.yes_no"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input :rows="1" :disabled="isView" v-model="dataForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="margin-top: -10px;">
          <h3 align="center">个性化设置</h3>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="出勤天数" prop="dutyDay">
                <el-input v-model="dataForm.dutyDay" placeholder="出勤天数" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="全勤奖" prop="fullDutyFee">
                <el-input v-model="dataForm.fullDutyFee" placeholder="全勤奖" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="提成比例" prop="royaltyRatio">
                <el-input v-model="dataForm.royaltyRatio" placeholder="提成比例" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提货日期">
                <el-date-picker
                  v-model="dataForm.takeGoodsTime"
                  type="daterange"
                  align="right"
                  unlink-panels range-separator="-"
                  size="small"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 100%"
                  :picker-options="$store.state.common.pickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="基本工资" prop="baseWage">
                <el-input v-model="dataForm.baseWage" placeholder="可输入基本工资" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="安全奖罚金" prop="safetyRewards">
                <el-input v-model="dataForm.safetyRewards" placeholder="可输入安全奖罚金" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="基本工资比例" prop="baseWageRatio">-->
<!--                <el-input v-model="dataForm.baseWageRatio" placeholder="基本工资比例,最多2位小数" :disabled="isView"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="绩效奖罚金" prop="meritPay">
                <el-input v-model="dataForm.meritPay" placeholder="可输入绩效奖罚金" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="租房费" prop="other1Reward">
                <el-input v-model="dataForm.other1Reward" placeholder="可输入租房费" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="绩效工资比例" prop="useAppReward">-->
<!--                <el-input v-model="dataForm.meritsWageRatio" placeholder="使用APP奖励,最多2位小数" :disabled="isView"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="APP奖罚金" prop="appRewards">
                <el-input v-model="dataForm.appRewards" placeholder="可输入APP奖罚金" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="油耗奖励" prop="other2Reward">
                <el-input v-model="dataForm.other2Reward" placeholder="可输入油耗奖励" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="APP奖励比例" prop="useAppReward">-->
<!--                <el-input v-model="dataForm.useAppReward" placeholder="使用APP奖励,最多2位小数" :disabled="isView"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="扣社保/公积金" prop="deductions">
                <el-input v-model="dataForm.deductions" placeholder="可输入扣社保/公积金" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="扣违章/油耗" prop="oilConsumption">
                <el-input v-model="dataForm.oilConsumption" placeholder="可输入扣违章/油耗" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="扣安全奖" prop="safetyAward">
                <el-input v-model="dataForm.safetyAward" placeholder="可输入扣安全奖" :disabled="isView"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      cardBusinessType: 5000,
      proofBusinessType: 5001,
      dataForm: {
        id: null,
        name: null,
        cardNo: null,
        motorcadeId: null,
        nativeCity: null,
        contactPhone: null,
        urgentPhone: null,
        proofType: null,
        isHaveVehicle: null,
        nowCity: null,
        address: null,
        remark: null,
        baseWage: null,
        entryDate: null,
        quitDate: null,
        royaltyRatio: null,
        dutyDay: null,
        fullDutyFee: null,
        takeGoodsTime: null,
        baseWageRatio: null,
        meritsWageRatio: null,
        useAppReward: null,
        other1Reward: null,
        other2Reward: null,
        captain: null,
        status: 1,
        enclosureInfoList: [],
        appRewards: null,
        meritPay: null,
        safetyRewards: null,
        deductions: null,
        oilConsumption: null,
        safetyAward: null,
        bankCard: null,
        bankType: null
      },
      cardType: 5000,
      otherCardType: 5010,
      driverProofType: 5001,
      qualificationType: 5011,
      motorcadeList: [],
      uploadUrl: '',
      accessToken: '',
      todo: '',
      dataRule: {
        name: this._Valid.init(['null']),
        motorcadeId: this._Valid.init(['null']),
        cardNo: this._Valid.init(['idCard']),
        contactPhone: this._Valid.init(['null']),
        proofType: this._Valid.init(['null']),
        isHaveVehicle: this._Valid.init(['null'])
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  methods: {
    clearFileList () {
      this.uploadUrl = this.$http.adornUrl('/upload/img')
      this.accessToken = this.$cookie.get('TmsToken')
      this.dataForm.enclosureInfoList = []
    },
    initFileList (data) {
      if (data.enclosureInfoList) {
        this.dataForm.enclosureInfoList = data.enclosureInfoList
      }
    },
    init (todo, id) {
      this.dataForm.id = id
      this.todo = todo
      this.clearFileList()
      this.$http({
        url: this.$http.adornUrl('/motorcade/findListBySelect'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.motorcadeList = data
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/driver/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            if (data) {
              this.dataForm.name = data.name
              this.dataForm.cardNo = data.cardNo
              this.dataForm.motorcadeId = data.motorcadeId
              this.dataForm.nativeCity = data.nativeCity
              this.dataForm.contactPhone = data.contactPhone
              this.dataForm.urgentPhone = data.urgentPhone
              this.dataForm.proofType = data.proofType
              this.dataForm.isHaveVehicle = data.isHaveVehicle
              this.dataForm.nowCity = data.nowCity
              this.dataForm.address = data.address
              this.dataForm.remark = data.remark
              this.dataForm.baseWage = data.baseWage
              this.dataForm.entryDate = data.entryDate
              this.dataForm.quitDate = data.quitDate
              this.dataForm.royaltyRatio = data.royaltyRatio
              this.dataForm.dutyDay = data.dutyDay
              this.dataForm.fullDutyFee = data.fullDutyFee
              this.dataForm.baseWageRatio = data.baseWageRatio
              this.dataForm.meritsWageRatio = data.meritsWageRatio
              this.dataForm.useAppReward = data.useAppReward
              this.dataForm.other1Reward = data.other1Reward
              this.dataForm.other2Reward = data.other2Reward
              this.dataForm.status = data.status
              this.dataForm.captain = data.captain
              this.dataForm.meritPay = data.meritPay
              this.dataForm.appRewards = data.appRewards
              this.dataForm.deductions = data.deductions
              this.dataForm.safetyRewards = data.safetyRewards
              this.dataForm.oilConsumption = data.oilConsumption
              this.dataForm.safetyAward = data.safetyAward
              this.dataForm.bankCard = data.bankCard
              this.dataForm.bankType = data.bankType
              this.initFileList(data)
            }
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let takeGoodsTime = this.dataForm.takeGoodsTime
          let timeParam = {}
          if (takeGoodsTime && takeGoodsTime.length === 2) {
            timeParam.takeGoodsBeginDate = takeGoodsTime[0]
            timeParam.takeGoodsEndDate = takeGoodsTime[1]
          }
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/driver/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'cardNo': this.dataForm.cardNo,
              'motorcadeId': this.dataForm.motorcadeId,
              'nativeCity': this.dataForm.nativeCity,
              'contactPhone': this.dataForm.contactPhone,
              'urgentPhone': this.dataForm.urgentPhone,
              'proofType': this.dataForm.proofType,
              'isHaveVehicle': this.dataForm.isHaveVehicle,
              'nowCity': this.dataForm.nowCity,
              'address': this.dataForm.address,
              'status': this.dataForm.status,
              'baseWage': this.dataForm.baseWage,
              'entryDate': this.dataForm.entryDate,
              'quitDate': this.dataForm.quitDate,
              'royaltyRatio': this.dataForm.royaltyRatio,
              'dutyDay': this.dataForm.dutyDay,
              'fullDutyFee': this.dataForm.fullDutyFee,
              'registerStatus': 10,
              'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
              'takeGoodsEndDate': timeParam.takeGoodsEndDate,
              'baseWageRatio': this.dataForm.baseWageRatio,
              'meritsWageRatio': this.dataForm.meritsWageRatio,
              'useAppReward': this.dataForm.useAppReward,
              'other1Reward': this.dataForm.other1Reward,
              'other2Reward': this.dataForm.other2Reward,
              'captain': this.dataForm.captain,
              'enclosureInfoList': this.dataForm.enclosureInfoList,
              'remark': this.dataForm.remark,
              'meritPay': this.dataForm.meritPay,
              'appRewards': this.dataForm.appRewards,
              'deductions': this.dataForm.deductions,
              'safetyRewards': this.dataForm.safetyRewards,
              'oilConsumption': this.dataForm.oilConsumption,
              'safetyAward': this.dataForm.safetyAward,
              'bankCard': this.dataForm.bankCard,
              'bankType': this.dataForm.bankType
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    getPhotoType (type) {
      let enclosureInfoList = this.dataForm.enclosureInfoList
      if (!enclosureInfoList) {
        return ''
      }
      for (let i = 0; i < enclosureInfoList.length; i++) {
        if (enclosureInfoList[i].businessType === type) {
          return 'https://rtd-tms.oss-cn-shenzhen.aliyuncs.com/' + enclosureInfoList[i].url
        }
      }
      return ''
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      let enclosureInfoList = this.dataForm.enclosureInfoList
      for (let i = 0; i < enclosureInfoList.length; i++) {
        if (enclosureInfoList[i].businessType === resData.businessType) {
          enclosureInfoList.splice(i, 1)
          break
        }
      }
      enclosureInfoList.push({ id: resData.id, businessType: resData.businessType, url: resData.url })
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    },
    beforeRemoveFileList () {
      if (this.todo === 'view') {
        return false
      }
    }
  }
}
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.avatar {
  width: 50px;
  height: 50px;
  display: block;
}
</style>
